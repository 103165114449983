document.addEventListener('DOMContentLoaded', function() {

  // Register custom info.json
  let customIIIF = document.querySelector('#custom-iiif');
  customIIIF.addEventListener('submit', (e) => {
    let input = document.querySelector('#custom-iiif__input');
    fetch(input.value).then((res) => {
      res.json().then((data) => {
        let imageContext = data['@context'] || '';
        let validContexts = [
          'http://library.stanford.edu/iiif/image-api/1.1/context.json',
          /iiif.io\/api\/image\/[1-9]+(.[0-9]+)*\/context.json$/g
        ];
        let valid = validContexts.some((context) => {
          return imageContext.match(context);
        });
        if (valid) {
          window.location = window.location.protocol + '//' + window.location.host + window.location.pathname + '?image=' + input.value;
        } else {
          throw 'The URL provided does not resolve to a valid IIIF resource';
        }
      }).catch((err) => {
        // Just assume it isn't valid for above for now if parse throws
        outputError('The URL provided does not resolve to a valid IIIF resource');
      });
    }).catch((err) => {
      outputError(err);
    });

    function outputError(err) {
      let errorElem = document.querySelector('#error-msg');
      if (!errorElem) {
        errorElem = document.createElement('p');
        errorElem.setAttribute('id', 'error-msg');
        errorElem.classList.add('error');
        input.insertAdjacentElement('beforeBegin', errorElem);
      }
      input.classList.add('custom-iiif__input--invalid');
      errorElem.innerText = err;
      console.warn(err);
    }
    e.preventDefault();
    return false;
  });
});
